<template>
  <el-col class="content">
    <ul class="search">
      <li class="teacher-item" :class="selectedTeacherId == teacher.id ? 'active' : ''" v-for="teacher in teachers" :key="teacher.id" @click="onSelectTeacher(teacher.id)">{{ teacher.nick_name }}</li>
    </ul>
    <div class="table">
      <div id="calendar" v-loading="loading"></div>
    </div>
  </el-col>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'

@Options({
  props: {
    msg: String
  }
})
export default class Schedule extends Vue {
  msg!: string
  events: any[] = []
  teachers: any[] = []
  selectedTeacherId: number | null = null
  calendar!: Calendar
  loading = false

  startDateStr: string | null = null
  endDateStr: string | null = null

  mounted () {
    const calendarEl = document.getElementById('calendar')
    if (calendarEl as HTMLElement) {
      const calendar = new Calendar((calendarEl as HTMLElement), {
        plugins: [
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin
        ],
        initialView: 'timeGridWeek',
        editable: true,
        allDaySlot: false,
        slotMinTime: '07:00:00',
        slotMaxTime: '22:00:00',
        height: '100%',
        datesSet: this.onDatesSet
      })
      this.calendar = calendar
      calendar.render()
    }

    this.loadTeachers()
  }

  loadTeachers () {
    this.axios.get('https://schedule.maxmusic.xsalo.com/teachers')
      .then((response: any) => {
        this.teachers = response.data
      })
  }

  onSelectTeacher (teacherId: number) {
    this.selectedTeacherId = teacherId

    this.loadTeacherLessons()
  }

  loadTeacherLessons () {
    if (this.selectedTeacherId == null) return

    this.loading = true
    const params = {
      time_begin: this.startDateStr,
      time_end: this.endDateStr
    }
    this.axios.get('https://schedule.maxmusic.xsalo.com/teachers/' + this.selectedTeacherId + '/lessons', { params: params })
      .then((response: any) => {
        this.calendar.getEvents().forEach((e) => e.remove())

        this.events = response.data
        for (const e of this.events) {
          const duration = e.subject.duration * e.quota
          const end = moment(e.time).add(duration, 'minutes').format()

          const studentsName = e.students.map((s: any) => s.nick_name).join(',')

          this.calendar.addEvent({
            title: e.subject.name + ' - ' + studentsName,
            start: e.time,
            end: end,
            editable: false
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  onDatesSet (dateInfo: any) {
    this.startDateStr = dateInfo.startStr
    this.endDateStr = dateInfo.endStr
    this.loadTeacherLessons()
  }
}
</script>

<style scoped>
ul {
  display: flex;
}

.teacher-item {
  width: 50px;
  margin-right: 20px;
}

.active {
  color: blue;
  font-weight: 600;
}

#calendar {
  width: 100%;
  height: 1500px;
}

.table {
  height: calc(100% - 49px);
}

.content {
  height: 100%;
}

.search {
  margin-bottom: 10px;
}
</style>
