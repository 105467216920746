import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_col, { class: "content" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: _ctx.subjectsPage.data,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "科目",
            width: "300"
          }),
          _createVNode(_component_el_table_column, {
            prop: "duration",
            label: "时长（分钟）"
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.loading]
      ]),
      _createVNode(_component_el_row, { class: "footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.so.page_no,
            "page-size": _ctx.so.page_size,
            total: _ctx.subjectsPage.total,
            "page-sizes": [200, 300, 400],
            small: "",
            background: "",
            layout: "total, sizes, prev, pager, next, jumper",
            class: "mt-4",
            onSizeChange: _ctx.onPageSizeChanged,
            onCurrentChange: _ctx.onPageNoChanged
          }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}