import { createRouter, createWebHistory } from 'vue-router'
import Schedule from '../page/Schedule.vue'
import Students from '../page/Students.vue'
import Subjects from '../page/Subjects.vue'
import Teachers from '../page/Teachers.vue'

const routes = [
  { path: '/students', component: Students },
  { path: '/schedules', component: Schedule },
  { path: '/subjects', component: Subjects },
  { path: '/teachers', component: Teachers }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
