<template>
  <el-col class="app-main">
    <el-row>
      <div class="header">
        Maxmusic魔方音乐体验中心 后台管理系统
      </div>
    </el-row>

    <el-row class="main-height">
      <el-col :span="3">
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="$route.path"
          :router="true"
        >
          <el-menu-item index="/schedules">
            <el-icon><icon-menu /></el-icon>
            <span>耗课管理</span>
          </el-menu-item>

          <el-menu-item index="/students">
            <el-icon><icon-menu /></el-icon>
            <span>学生管理</span>
          </el-menu-item>

          <el-menu-item index="/teachers">
            <el-icon><icon-menu /></el-icon>
            <span>教师管理</span>
          </el-menu-item>

          <el-menu-item index="/subjects">
            <el-icon><icon-menu /></el-icon>
            <span>课程管理</span>
          </el-menu-item>

          <!-- <el-menu-item index="/settings">
            <el-icon><icon-menu /></el-icon>
            <span>系统管理</span>
          </el-menu-item> -->
        </el-menu>
      </el-col>

      <el-col :span="21" class="main">
        <router-view></router-view>
      </el-col>
    </el-row>
  </el-col>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import router from '@/router'

@Options({
  components: {
  }
})
export default class App extends Vue {
}
</script>

<style>
html body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
}

.app-main {
  height: 100%;
}

.header {
  padding: 20px;
  height: 30px;
}

.main-height {
  height: calc(100% - 70px);
}

.main {
  height: 100%;
  padding: 20px;
}
</style>
