<template>
  <el-col class="content">
    <el-row class="search">
      <el-col :span="5">
        <el-select v-model="so.deleted" placeholder="状态" style="width: 115px">
          <el-option label="正常" value="false" />
          <el-option label="已删除" value="true" />
        </el-select>
      </el-col>
      <el-col :span="5">
        <el-button type="primary" @click="loadStudents">查询</el-button>
      </el-col>
    </el-row>

    <el-table class="table" :data="studentsPage.data" style="width: 100%" v-loading="loading">
      <el-table-column prop="nick_name" label="昵称" width="180" />
      <el-table-column prop="consume_lessons_in_30_days" sortable label="过去30天耗课" width="180" />
      <el-table-column prop="total_balance" label="课程余额" sortable width="180" />
      <el-table-column prop="last_consume_time" label="上次耗课时间" :formatter="lastConsumeTimeFormatter" />
      <el-table-column prop="deleted" label="状态" width="180" :formatter="deletedFormatter" />
      <el-table-column prop="created_at" label="登记时间" :formatter="dateFormatter" />
    </el-table>

    <el-row class="footer">
      <el-pagination
        :current-page="so.page_no"
        :page-size="so.page_size"
        :total="studentsPage.total"
        :page-sizes="[200, 300, 400]"
        small
        background
        layout="total, sizes, prev, pager, next, jumper"
        class="mt-4"
        @size-change="onPageSizeChanged"
        @current-change="onPageNoChanged"
      />
    </el-row>
  </el-col>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Page, Student } from '../models/Objects'
import { AxiosResponse } from 'axios'
import { TableColumnCtx } from 'element-plus'
import moment from 'moment'

export default class Students extends Vue {
  studentsPage: Page<Student> = new Page()

  loading = false
  so = {
    deleted: 'false',
    page_no: 1,
    page_size: 200
  }

  mounted (): void {
    this.loadStudents()
  }

  loadStudents () {
    this.loading = true
    this.axios.get('https://schedule.maxmusic.xsalo.com/students_page', { params: this.so })
      .then((response: AxiosResponse<Page<Student>>) => {
        this.studentsPage = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  onPageSizeChanged (size: number) {
    this.so.page_size = size
    this.loadStudents()
  }

  onPageNoChanged (no: number) {
    this.so.page_no = no
    this.loadStudents()
  }

  deletedFormatter (row: any, column: TableColumnCtx<any>, cellValue: any, index: number): string {
    return cellValue ? '已删除' : '正常'
  }

  lastConsumeTimeFormatter (row: any, column: TableColumnCtx<any>, cellValue: any, index: number) {
    const time = this.dateTimeFormatter(row, column, cellValue, index)
    if (time) return time
    else return '没有耗课记录'
  }

  dateFormatter (row: any, column: TableColumnCtx<any>, cellValue: any, index: number): string {
    return moment(cellValue).local().format('YYYY-MM-DD')
  }

  dateTimeFormatter (row: any, column: TableColumnCtx<any>, cellValue: any, index: number): string | null {
    if (cellValue) {
      return moment(cellValue).local().format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }
}
</script>

<style scoped>

.table {
  height: calc(100% - 40px - 42px);
}

.content {
  height: 100%;
}

.footer {
  padding: 20px 0;
}

.search {
  margin-bottom: 10px;
}
</style>
