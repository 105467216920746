<template>
  <el-col class="content">
    <!-- <el-col>
      <el-row class="so-row">
        <el-select v-model="so.deleted" placeholder="状态" style="width: 115px">
          <el-option label="正常" value="false" />
          <el-option label="已删除" value="true" />
        </el-select>
      </el-row>
      <el-row class="so-row">
        <el-button type="primary" @click="loadSubjects">查询</el-button>
      </el-row>
    </el-col> -->

    <el-table class="table" :data="subjectsPage.data" style="width: 100%" v-loading="loading">
      <el-table-column prop="name" label="科目" width="300" />
      <el-table-column prop="duration" label="时长（分钟）" />
    </el-table>

    <el-row class="footer">
      <el-pagination
        :current-page="so.page_no"
        :page-size="so.page_size"
        :total="subjectsPage.total"
        :page-sizes="[200, 300, 400]"
        small
        background
        layout="total, sizes, prev, pager, next, jumper"
        class="mt-4"
        @size-change="onPageSizeChanged"
        @current-change="onPageNoChanged"
      />
    </el-row>
  </el-col>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Subject, Page } from '../models/Objects'
import { AxiosResponse } from 'axios'
import { TableColumnCtx } from 'element-plus'
import moment from 'moment'

export default class Subjects extends Vue {
  subjectsPage: Page<Subject> = new Page()

  loading = false;
  so = {
    deleted: 'false',
    page_no: 1,
    page_size: 200
  }

  mounted (): void {
    this.loadSubjects()
  }

  loadSubjects () {
    this.loading = true
    this.axios
      .get(
        'https://schedule.maxmusic.xsalo.com/subjects_page',
        { params: this.so }
      )
      .then((response: AxiosResponse<Page<Subject>>) => {
        this.subjectsPage = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  deletedFormatter (
    row: any,
    column: TableColumnCtx<any>,
    cellValue: any,
    index: number
  ): string {
    return cellValue ? '已删除' : '正常'
  }

  dateFormatter (
    row: any,
    column: TableColumnCtx<any>,
    cellValue: any,
    index: number
  ): string {
    return moment(cellValue).local().format('YYYY-MM-DD')
  }

  onPageSizeChanged (size: number) {
    this.so.page_size = size
    this.loadSubjects()
  }

  onPageNoChanged (no: number) {
    this.so.page_no = no
    this.loadSubjects()
  }
}
</script>

<style scoped>
.so-row {
  margin-bottom: 10px;
}

.table {
  height: calc(100% - 40px);
}

.content {
  height: 100%;
}

.footer {
  padding: 20px 0;
}

</style>
