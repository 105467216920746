import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_col, { class: "content" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, { class: "search" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.so.deleted,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.so.deleted) = $event)),
                placeholder: "状态",
                style: {"width":"115px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "正常",
                    value: "false"
                  }),
                  _createVNode(_component_el_option, {
                    label: "已删除",
                    value: "true"
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.loadStudents
              }, {
                default: _withCtx(() => [
                  _createTextVNode("查询")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: _ctx.studentsPage.data,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "nick_name",
            label: "昵称",
            width: "180"
          }),
          _createVNode(_component_el_table_column, {
            prop: "consume_lessons_in_30_days",
            sortable: "",
            label: "过去30天耗课",
            width: "180"
          }),
          _createVNode(_component_el_table_column, {
            prop: "total_balance",
            label: "课程余额",
            sortable: "",
            width: "180"
          }),
          _createVNode(_component_el_table_column, {
            prop: "last_consume_time",
            label: "上次耗课时间",
            formatter: _ctx.lastConsumeTimeFormatter
          }, null, 8, ["formatter"]),
          _createVNode(_component_el_table_column, {
            prop: "deleted",
            label: "状态",
            width: "180",
            formatter: _ctx.deletedFormatter
          }, null, 8, ["formatter"]),
          _createVNode(_component_el_table_column, {
            prop: "created_at",
            label: "登记时间",
            formatter: _ctx.dateFormatter
          }, null, 8, ["formatter"])
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _ctx.loading]
      ]),
      _createVNode(_component_el_row, { class: "footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.so.page_no,
            "page-size": _ctx.so.page_size,
            total: _ctx.studentsPage.total,
            "page-sizes": [200, 300, 400],
            small: "",
            background: "",
            layout: "total, sizes, prev, pager, next, jumper",
            class: "mt-4",
            onSizeChange: _ctx.onPageSizeChanged,
            onCurrentChange: _ctx.onPageNoChanged
          }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}