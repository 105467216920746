<template>
  <el-col class="content">
    <el-table class="table" :data="teachersPage.data" style="width: 100%" v-loading="loading">
      <el-table-column prop="nick_name" label="昵称" width="300" />
      <el-table-column prop="mobile" label="电话" width="300" />

      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="onClickBindOperation(scope.row)">绑定微信</el-button>
          <el-button link type="primary" size="small" @click="onUnbindWX">解绑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="footer">
      <el-pagination
        :current-page="so.page_no"
        :page-size="so.page_size"
        :total="teachersPage.total"
        :page-sizes="[200, 300, 400]"
        small
        background
        layout="total, sizes, prev, pager, next, jumper"
        class="mt-4"
        @size-change="onPageSizeChanged"
        @current-change="onPageNoChanged"
      />
    </el-row>

    <el-dialog v-model="bindFormVisible" title="绑定微信小程序">
      <el-form :model="bindForm">
        <el-form-item label="老师" :label-width="200">
          <el-text>{{ bindForm.teacherNickName }}</el-text>
        </el-form-item>

        <el-form-item label="绑定ID（从小程序中获取）" :label-width="200">
          <el-input v-model="bindForm.bindId" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="bindFormVisible = false">取消</el-button>
          <el-button type="primary" @click="onBindWX">绑定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-col>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import { Teacher, Page } from '../models/Objects'
import { AxiosResponse } from 'axios'
import { TableColumnCtx } from 'element-plus'
import moment from 'moment'

export default class Teachers extends Vue {
  teachersPage: Page<Teacher> = new Page()
  bindFormVisible = false

  loading = false
  so = {
    deleted: 'false',
    page_no: 1,
    page_size: 200
  }

  bindForm = {
    bindId: '',
    teacherNickName: '',
    teacherId: null as number | null
  }

  mounted (): void {
    this.loadSubjects()
  }

  loadSubjects () {
    this.loading = true
    this.axios
      .get(
        'https://schedule.maxmusic.xsalo.com/teachers_page',
        { params: this.so }
      )
      .then((response: AxiosResponse<Page<Teacher>>) => {
        this.teachersPage = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  deletedFormatter (
    row: any,
    column: TableColumnCtx<any>,
    cellValue: any,
    index: number
  ): string {
    return cellValue ? '已删除' : '正常'
  }

  dateFormatter (
    row: any,
    column: TableColumnCtx<any>,
    cellValue: any,
    index: number
  ): string {
    return moment(cellValue).local().format('YYYY-MM-DD')
  }

  onPageSizeChanged (size: number) {
    this.so.page_size = size
    this.loadSubjects()
  }

  onPageNoChanged (no: number) {
    this.so.page_no = no
    this.loadSubjects()
  }

  onClickBindOperation (teacher: any) {
    this.bindForm.teacherId = teacher.id
    this.bindForm.teacherNickName = teacher.nick_name

    this.bindFormVisible = true
  }

  onBindWX () {
    this.bindFormVisible = false
    console.log('bind')
  }

  onUnbindWX () {
    console.log('unbind')
  }
}
</script>

<style scoped>
.so-row {
  margin-bottom: 10px;
}

.table {
  height: calc(100% - 40px);
}

.content {
  height: 100%;
}

.footer {
  padding: 20px 0;
}

</style>
