export class Page<T> {
    total = 0
    data: T[] = []
}

export class Student {
    nickName!: string
    createdAt!: string
}

export class Teacher {
    id!: number
    nickName!: string
}

export class Subject {
    name!: string
    duration!: number
}
